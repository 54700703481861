<template>
  <b-modal id="import-table-modal" 
    :title="title"
    @hidden="setDefaultForm"
    centered 
    size="xl"
  >
    <b-container v-show="!showModalLoading">
      <b-row>
        <b-col cols="8">
          <label for="name" class="label">
            Tabela
          </label>
          <multiselect
            class="form-control p-0"
            v-model="form.table"
            :options="getOptions"
            label="label" 
            track-by="name" 
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            :showLabels="false" 
            placeholder="Selecionar"
            openDirection="bottom"
          >
            <template slot="noOptions">As tabelas já foram importadas</template>
          </multiselect>
        </b-col>
        <b-col cols="4">
          <label for="date" class="label">
            Data vigência inicial
          </label>
          <date-picker
            id="date"
            v-model="form.date"
            format="DD/MM/YYYY"
            placeholder="DD/MM/AAAA"
            :disabled-date="disabledDates"
            :clearable="false"
            class="full"
            autocomplete="off"
          />
        </b-col>
      </b-row>
      <div v-if="form.table" class="import-container">
        <b-row class="w-100">
          <b-col class="import-progress">
            <div v-if="form.file" class="w-100">
              <b-row class="justify-content-center mb-3">
                {{ form.file?.name }}
              </b-row>
              <b-row v-if="importing == null" class="w-100 justify-content-center">
                <b-button 
                  variant="outline-danger"
                  @click="() => $set(form, 'file', null)"
                >
                  Remover
                </b-button>
                <b-button 
                  variant="primary" 
                  @click="sendTable()"
                  :disabled="!form.date"
                >
                  Enviar tabela
                </b-button>
              </b-row>
              <b-row v-else-if="importing !== null" class="w-100 justify-content-center">
                <b-progress
                  :value="importing"
                  :max="100"
                  show-value
                  class="mb-3 w-50"
                  :variant="showErrors() ? 'danger' : 'success'"
                ></b-progress>
              </b-row>
            </div>
            <div v-else-if="!form.file">
              <label @drop="dragFile">
                <input type="file" @change="onFileSelect" class="import-file" accept=".xlsx,.csv" />
                Clique aqui ou solte o arquivo nessa área para selecionar
              </label>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="showErrors()" class="w-100">
          <b-table-simple striped>
            <tbody>
              <tr>
                <th scope="col">Linha</th>
                <th scope="col">Descrição da falha</th>
              </tr>
              <tr v-for="(error, index) in importErrors" :key="index">
                <td>{{ error.row }}</td>
                <td>{{ error.message }}</td>
              </tr>
            </tbody>
          </b-table-simple>
        </b-row>
      </div>
    </b-container>
    <template #modal-footer>
      <div>
        <b-button variant="outline-danger" @click="closeModal">
          Cancelar
        </b-button>
      </div>
    </template>
    <div v-show="showModalLoading">
      <div class="text-center text-hover-primary my-2">
        <b-spinner class="align-middle" style="margin-right: 10px;" small></b-spinner>
        <strong>Carregando...</strong>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'
import moment from 'moment'
import api from '@/modules/tiss/matMed/api'

export default {
  name: 'ImportTableModal',
  props: {
    isUpdate: {
      type: Boolean,
      default: false,
    },
    tableType: {
      type: String,
      default: '',
    },
    tableId: {
      type: String,
      default: '',
    },
    importedTables: {
      type: Array,
      default: () => [],
    },
    importErrors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      title: "Importar tabela",
      options: [
        {'name': 'simpro', 'label': 'Tabela Simpro'},
        {'name': 'brasindice', 'label': 'Tabela Brasindice'}
      ],
      form: {
        table: null,
        date: null,
        file: null,
        name: null
      },
      importing: null,
      showModalLoading: false,
      hasErrors: false
    }
  },
  mounted() {
    this.showModalLoading = false
    
    if (this.isUpdate) {
      this.title = "Atualizar tabela"
      this.form.table = this.getTableName()
    }
  },
  computed: {
    getOptions() {
      if (this.isUpdate) {
        return this.getTableName()
      }

      return this.options.filter(item => !this.importedTables.includes(item.name))
    },
  },
  methods: {
    showErrors() {
      return this.importErrors.length || this.hasErrors
    },
    getTableName() {
      return this.options.filter(item => item.name === this.tableType)
    },
    disabledDates(date) {
      const momentDate = this.moment(date).startOf('day')
      return momentDate.isBefore(this.moment().startOf('day'))
    },
    dragFile(e) {
      e.preventDefault()
      e.stopPropagation()
      this.$set(this.form, 'file', e.target.files[0])
    },
    onFileSelect(e) {
      this.$set(this.form, 'file', e.target.files[0])
    },
    setDefaultForm() {
      this.form = {
        table: null,
        date: null,
        file: null,
        name: null
      }
      this.importing = null
      this.hasErrors = false
      this.showModalLoading = false
    },
    async sendTable() {
      this.isLoading = true

      try {
        const formData = new FormData()

        this.helpers.buildFormData(formData, {
          file: this.form.file,
          name: this.form.name,
          effective_date: moment(this.form.date).format('YYYY-MM-DD HH:mm:ss'),
          type: this.form.table.name || this.tableType,
          clinic_id: this.clinic.id,
          table_id: this.tableId,
        })

        this.importing = 0

        await api.uploadMaterialMedicinesTable(formData, {
          onUploadProgress: progressEvent => {
            this.importing = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          }
        })

        this.setDefaultForm()

        this.$toast.success('Tabela importada com sucesso!')

        this.title = 'Processando...'
        this.showModalLoading = true
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.isLoading = false
      }
    },
    closeModal() {
      this.form = {}
      this.hasErrors = false
      this.$bvModal.hide('import-table-modal')
    }
  },
}
</script>

<style lang="scss" scoped>
.import-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  border: 1px dashed var(--neutral-300);
  border-radius: 5px;

  .import-progress {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 100%;
    border-radius: 4px;
    cursor: pointer;
  }

  .import-file {
    display: none;
    width: 100%;
    height: 100%;
  }

  .table {
    margin-left: 15px;
  }

  .btn-primary {
    margin-left: 10px;
    height: 40px;
    font-size: 16px;
  }

  .btn-outline-danger {
    border-width: 2px;
    border-radius: 8px;
    font-weight: bold;
  }
}
</style>